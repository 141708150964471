/* Контейнер для новин */
.news-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 10px;
  margin-top: 20px;
}

/* Картка новини */
.news-card {
  display: flex;
  align-items: center;
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  padding: 8px;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
}

.news-card:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

/* Зображення новини */
.news-image {
  width: 150px; /* зменшено */
  height: auto;
  object-fit: cover;
  border-radius: 6px;
  margin-right: 12px;
}

/* Контент */
.news-content {
  flex: 1;
  display: flex;
  flex-direction: column;
}

/* Заголовок */
.news-title {
  font-size: 14px; /* зменшено */
  font-weight: bold;
  color: #222;
  text-decoration: none;
  line-height: 1.3;
}

.news-title:hover {
  text-decoration: underline;
}

/* Розділ */
.news-section {
  font-size: 9px; /* зменшено */
  color: black;
  padding: 4px 8px;
  border-radius: 4px;
  border: 1px solid black;
  display: inline-block;
  text-transform: uppercase;
  margin-bottom: 5px;
}

.news-meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px; /* трохи менше */
}

/* Дата */
.news-date {
  font-size: 11px; /* зменшено */
  color: black;
}

/* Адаптивність */
@media (max-width: 768px) {
  .news-container {
    grid-template-columns: 1fr;
  }

  .news-card {
    flex-direction: column;
    align-items: flex-start;
  }

  .news-image {
    width: 100%;
    height: 160px;
    margin-bottom: 10px;
  }
}
