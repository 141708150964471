.subsections-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 20px;
}

.subsection-item {
  position: relative;
  display: inline-block;
  text-align: center;
  cursor: pointer;
}

.subsection-image {
  width: 80px;
  height: 80px;
  transition: transform 0.3s ease;
  object-fit: contain;
}

.club-logo-small {
  width: 60px;
  height: 60px;
  transition: transform 0.3s ease;
  object-fit: contain;
}


.subsection-item:hover .subsection-image {
  transform: scale(1.1);
}

.subsection-title {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 4px 6px;
  font-size: 12px;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.subsection-item:hover .subsection-title {
  opacity: 1;
}



  