/* 🌑 Фон сторінки профілю */
.profile-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 20px;
  
  min-height: 50vh;
}

/* 💳 Основний блок профілю */
.profile-card {
  background: linear-gradient(145deg, #1e1e1e, #2b2b2b);
  padding: 40px;
  border-radius: 15px;
  width: 100%;
  max-width: 700px;
  animation: fadeIn 0.8s ease-in-out;
  box-shadow: 0 8px 20px rgba(0,0,0,0.6);
  border: 1px solid #333;
}

/* ✨ Анімація появи */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-15px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* 👤 Заголовок профілю */
.profile-card h1 {
  font-size: 28px;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
  margin-bottom: 25px;
}

/* 📊 Таблиця профілю */
.profile-table {
  width: 100%;
  border-collapse: collapse;
}

.profile-table th,
.profile-table td {
  padding: 12px;
  text-align: left;
  font-size: 18px;
  border-bottom: 1px solid #444;
  color: #f0f0f0;
}

.profile-table th {
  background-color: transparent;
  font-weight: bold;
  color: #999;
  width: 40%;
}

/* 🟢🟥 Роль користувача */
.role-admin {
  color: #ff5252;
  font-weight: bold;
  text-transform: uppercase;
}

.role-user {
  color: #00e676;
  font-weight: bold;
  text-transform: uppercase;
}

/* 📱 Адаптивність */
@media (max-width: 768px) {
  .profile-container {
    padding: 20px 10px;
    flex-direction: column;
  }

  .profile-card {
    padding: 20px;
    border-radius: 10px;
  }

  .profile-card h1 {
    font-size: 22px;
    margin-bottom: 20px;
  }

  .profile-table th,
  .profile-table td {
    font-size: 14px;
    padding: 8px;
  }

  .profile-table th {
    width: 45%;
  }
}

  
  