.header-container {
  background-color: white;
  color: yellow;
  padding: 10px 0px;
}

/* Основна секція хедера */
.header-section-logo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 20px;
}

.search-bar-wrapper {
  display: block;
}

/* Логотип */
.logo-header {
  width: 200px;
  height: auto;
  margin-right: 15px;
}

/* Блок з кнопками */
.header-section-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

/* Блок з ім’ям користувача */
.header-user-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  color: black;
}

/* Стиль тексту (наприклад ім'я) */
span {
  font-size: 25px;
  font-style: italic;
  font-weight: bold;
}

/* ---------- Адаптація ---------- */

@media (max-width: 768px) {
  .header-section-logo {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .logo-header {
    width: 140px;
    margin: 0 auto 10px auto;
    display: block;
  }

  .search-bar-wrapper {
    display: none;
  }

  .header-section-button {
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 3px;
  }

  .header-user-section span {
    font-size: 12px;
  }

  .login-button,
  .register-button,
  .admin-button,
  .profile-button,
  .exit-button {
    padding: 3px 9px;
    font-size: 10px;
    border-radius: 10px;
    margin-right: 10px;
  }
}
