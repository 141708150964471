.add-news-table {
  width: 100%;
  border-collapse: collapse;
  background: white;
  margin-bottom: 50px;
}

.add-news-table td {
  padding: 10px;
  border: 1px solid #ddd;
}

.add-news-table label {
  font-weight: bold;
}

.add-news-table input,
.add-news-table select,
.add-news-table .ql-container {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.add-news-table .ql-container {
  height: 150px;
}


.table-submit-cell {
  text-align: center;
  padding-top: 10px;
}

