/* ----Стилі для пагінації сторінок---- */

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 60px; 
    background-color: black;
    padding: 10px 0; 
    box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1000; 
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .pagination button {
    padding: 10px 20px;
    margin: 0 5px;
    background-color: white;
    color: black;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .pagination button:hover {
    background-color: yellow;
    color: black;
  }
  
  .pagination span {
    color: yellow;
    font-size: 20px;
    margin: 0 10px;
  }
  
  