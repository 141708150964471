.edit-news-table {
    width: 100%;
    border-collapse: collapse;
    background: white;
    margin-bottom: 50px;
  }
  
  .edit-news-table td {
    padding: 10px;
    border: 1px solid #ddd;
  }
  
  .edit-news-table label {
    font-weight: bold;
  }
  
  .edit-news-table input,
  .edit-news-table select,
  .edit-news-table .ql-container {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .edit-news-table .ql-container {
    height: 150px;
  }
  
  