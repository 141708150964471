/* Контейнер для всього блоку коментарів */
.comments-container {
  width: 100%;
  max-width: 1200px;
  margin: 20px auto;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 25px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Заголовок блоку коментарів */
.comments-header {
  font-size: 22px;
  font-weight: bold;
  color: #222;
  margin-bottom: 15px;
  text-align: center;
}

/* Список коментарів */
.comments-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

/* Окремий коментар */
.comment-item {
  background: #f9f9f9;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Хедер коментаря (користувач + дата) */
.comment-header {
  font-size: 14px;
  font-weight: bold;
  color: #555;
  margin-bottom: 8px;
}

/* Текст коментаря */
.comment-body {
  font-size: 16px;
  color: #333;
  line-height: 1.5;
  margin-bottom: 10px;
}

/* Блок дій з коментарем (лайки, дизлайки, видалення) */
.comment-actions {
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 10px;
}

/* Кнопки */
.like-button, .dislike-button, .delete-button {
  display: flex;
  align-items: center;
  gap: 5px;
  border: none;
  background: none;
  font-size: 14px;
  cursor: pointer;
  transition: transform 0.2s ease;
  padding: 5px 10px;
  border-radius: 5px;
}

.like-button:hover, .dislike-button:hover, .delete-button:hover {
  transform: scale(1.1);
}

/* Іконки */
.like-button {
  color: #008CBA;
}

.dislike-button {
  color: #FF4500;
}

.delete-button {
  color: red;
  font-weight: bold;
}

@media (max-width: 768px) {
  .comments-container {
    padding: 15px;
    border-radius: 15px;
    margin: 15px 10px;
  }

  .comments-header {
    font-size: 20px;
    margin-bottom: 12px;
  }

  .comment-item {
    padding: 12px;
    border-radius: 6px;
  }

  .comment-header {
    font-size: 13px;
  }

  .comment-body {
    font-size: 15px;
  }

  .comment-actions {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 6px;
    margin-top: 8px;
  }

  .like-button,
  .dislike-button,
  .delete-button {
    font-size: 13px;
    padding: 4px 8px;
  }
}
