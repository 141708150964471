.admin_panel {
    max-width: 1400px;
  }

  /* 📱 Адаптація під мобільні */
@media (max-width: 768px) {
  .admin_panel {
    padding: 10px;
  }

  .admin_panel h1 {
    font-size: 20px;
    text-align: center;
    margin: 15px 0;
  }

  .add-news-table,
  .edit-news-table,
  .news-table {
    font-size: 14px;
    overflow-x: auto;
    display: block;
    width: 100%;
  }

  .add-news-table td,
  .edit-news-table td,
  .news-table td,
  .news-table th {
    padding: 6px;
    text-align: left;
    word-break: break-word;
  }

  .news-table button,
  .profile-button,
  .exit-button {
    font-size: 13px;
    padding: 6px 10px;
    margin: 2px;
  }

  .panel {
    margin-bottom: 20px;
  }

  .image-preview {
    max-width: 100%;
    height: auto;
  }
}