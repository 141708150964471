.search-container {
  position: relative;
  display: flex;
  align-items: center;
}

.search-icon {
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px;
  border-radius: 50%;
  transition: background 0.3s ease;
}

.search-icon:hover {
  background: rgba(0, 0, 0, 0.1);
}

.search-bar {
  position: absolute;
  right: 0;
  top: 0;
  transform: translateY(-50%);
  background: white;
  border-radius: 25px;
  display: flex;
  align-items: center;
  padding: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s ease;
}

.search-bar.open {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.search-bar input {
  border: none;
  outline: none;
  padding: 8px;
  font-size: 14px;
  width: 200px;
  border-radius: 25px;
}

.search-button {
  background-color: #ffcc00;
  border: none;
  padding: 8px;
  border-radius: 50%;
  cursor: pointer;
  transition: background 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.search-button:hover {
  background-color: #ffd633;
}
