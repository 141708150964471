/* Контейнер для новин */
.news-container-general{
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
  }
  
  /* Окрема картка новини */
  .news-card-general {
    display: flex;
    align-items: center;
    background: white;
    border-radius: 12px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    padding: 10px;
    transition: box-shadow 0.3s ease, transform 0.3s ease;
  }
  
  .news-card-general:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }
  
  /* Зображення новини */
  .news-image-general {
    width: 200px;
    height: auto;
    object-fit: cover;
    border-radius: 8px;
    margin-right: 15px;
  }
  
  /* Контент */
  .news-content-general {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  
  /* Заголовок новини */
  .news-title-general {
    font-size: 16px;
    font-weight: bold;
    color: #222;
    text-decoration: none;
  }
  
  .news-title-general:hover {
    text-decoration: underline;
  }
  .news-meta-general{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }


  /* Окремий стиль для категорії */
  .news-category-general {
    font-size: 10px;
    color: black;
    padding: 5px 10px;
    border-radius: 5px;
    border: 1px solid black;
    display: inline-block;
    text-transform: uppercase;
    margin-bottom: 5px;
  }
  
  /* Дата і статистика */
  .news-date-general {
    font-size: 12px;
    color: black;
  }
  
  /* 📱 Адаптивність */
  @media (max-width: 768px) {
    .news-card-general {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .news-image-general {
      width: 100%;
      height: 160px;
      margin-bottom: 10px;
    }
  }
  