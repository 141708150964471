.social-links {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin: 20px 0;
  }
  
  .social-icon {
    font-size: 24px;
    color: white;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    transition: transform 0.3s ease;
  }
  
  .social-icon.instagram {
    background: radial-gradient(circle at 30% 30%, #feda75, #d62976, #962fbf, #4f5bd5);
  }
  
  .social-icon.youtube {
    background-color: #FF0000;
  }
  
  .social-icon:hover {
    transform: scale(1.1);
  }
  
  @media (max-width: 768px) {
    .social-links {
      flex-direction: row;
      justify-content: center;
      gap: 16px;
      margin: 15px 0;
    }
  
    .social-icon {
      width: 40px;
      height: 40px;
      font-size: 20px;
    }
  }
  
  