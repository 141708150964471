
 /*----Основні стилі----*/
*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.App {
  font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  background-color:white;
  margin: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.container {
  max-width: 1400px;
  margin: 0 auto;
}

/*----Кнопки----*/
/*кнопка виходу*/
.login-button{
  margin-right: 20px;
  background-color: white;
  color: black;
  border: 2px solid black;
  padding: 10px 20px;
  border-radius: 15px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.login-button:hover {
  color: rgb(10, 10, 2);
  font-weight: bold;
  border: 1px solid rgb(10, 10, 2);
}
/*кнопка реєстрації*/
.register-button {
  margin-right: 20px;
  background-color: white;
  color: black;
  border: 2px solid black;
  padding: 10px 20px;
  border-radius: 15px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.register-button:hover {
  color: rgb(42, 10, 123);
  font-weight: bold;
  border: 1px solid rgb(42, 10, 123);
}
/*кнопка адміна*/
.admin-button{
  margin-right: 20px;
  background-color: white;
  color: black;
  border: 2px solid black;
  padding: 10px 20px;
  border-radius: 15px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.admin-button:hover {
  color: cornflowerblue;
  font-weight: bold;
  border: 1px solid cornflowerblue;
}
/*кнопка профілю*/
.profile-button{
  margin-right: 20px;
  background-color: white;
  color: black;
  border: 2px solid black;
  padding: 10px 20px;
  border-radius: 15px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.profile-button:hover{
  color: green;
  font-weight: bold;
  border: 1px solid green;
}
/*кнопка виходу*/
.exit-button{
  margin-right: 20px;
  background-color: white;
  color: black;
  border: 2px solid black;
  padding: 10px 20px;
  border-radius: 15px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.exit-button:hover {
  color: rgb(249, 99, 99);
  font-weight: bold;
  border: 1px solid rgb(249, 99, 99);
}
/* Кнопка закриття */
.close-button {
  background: none;
  border: none;
  color: black;
  font-size: 34px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 15px;
  font-weight: bold;
  transition: color 0.3s ease;
}
  
  .close-button:hover {
    color: red;
  }

/* Стиль для заголовків панелей */
.panel {
  margin-bottom: 10px;
}

.panel h1 {
  font-size: 18px;
  padding: 10px;
  margin-bottom: 10px;
  text-align: center;
  background-color: black;
  color: white;
}

.panel-news {
  margin-bottom: 10px;
}
.panel-news h1 {
  font-size: 18px;
  padding: 10px;
  margin-bottom: 10px;
  text-align: center;
  background-color: #FF0000;
  color: white;
  border: 1px solid black;
}

/* Загальний стиль для головного контейнера */
main {
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-top: 10px;
}







