/* Контейнер всієї новини */
.single-news-container {
  width: 100%;
  min-height: 100vh; /* Фон на всю висоту */
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 20px;
  color: white;
  /* Фіксований фон */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed; /* Фон залишається на місці */
}
/* Затемнення фону */
.single-news-overlay {
  width: 100%;
  max-width: 1200px;
  color: black;
  padding: 30px;
  border-radius: 12px;
  text-align: center;
  background: rgba(255, 255, 255, 0.95);
  margin-bottom: 15px;
}
/* Заголовок */
.single-news-title {
  font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font-size: 45px;
  font-weight: bold;
  text-align: center;
}
/* Окреме зображення новини */
.single-news-image-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 15px 0;
}

.single-news-article-image {
  max-width: 90%;
  height: auto;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
/* Контейнер для статті */
.single-news-content-wrapper {
  width: 100%;
  max-width: 1200px;
  max-height: 80vh; /* Максимальна висота */
  min-height: 10vh; /* Мінімальна висота */
  overflow-y: auto; /* Прокрутка якщо потрібно */
  background: rgba(255, 255, 255, 0.95);
  color: black;
  border-radius: 12px;
  padding: 25px;
  margin-top: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  max-height: none;
  min-height: auto;
  overflow: visible;
}
/* Основний контент */
.single-news-content {
  font-size: 18px;
  line-height: 1.6;
  padding-bottom: 20px;
}
/* Метадані */
.single-news-meta {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
}
.single-news-meta span{
  font-size: 16px;
}
/*стилі для фото та відео*/
.single-news-content img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 20px auto;
  border-radius: 8px;
}
.single-news-content iframe {
  width: 100%;
  height: 400px;
  display: block;
  margin: 20px auto;
  border: none;
  border-radius: 8px;
}




/* 📱 Мобільні пристрої */
@media (max-width: 768px) {
  .single-news-container {
    padding: 10px;
  }

  .single-news-overlay {
    padding: 15px;
  }

  .single-news-title {
    font-size: 28px;
  }

  .single-news-meta {
    font-size: 14px;
  }

  .single-news-content-wrapper {
    max-height: 70vh;
    overflow-y: auto;
    padding: 15px;
    max-height: 70vh; /* Менша висота на мобільних */
  }

  .single-news-article-image {
    max-width: 100%;
  }

  .single-news-content iframe {
    height: 250px;
  }
}

/* 📱 Планшети */
@media (min-width: 769px) and (max-width: 1024px) {
  .single-news-title {
    font-size: 32px;
  }

  .single-news-meta {
    font-size: 15px;
  }

  .single-news-content-wrapper {
    max-width: 900px;
    padding: 20px;
  }
}

