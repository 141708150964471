.top-slider-container {
  max-width: 830px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
}

.slider-wrapper {
  position: relative;
}

.slider-link {
  display: block;
  position: relative;
  text-decoration: none;
  color: white;
  transition: transform 0.3s ease;
}

.slider-link:hover {
  transform: scale(1.01);
}

.slider-image {
  width: 100%;
  height: 500px;
  object-fit: cover;
  transition: opacity 0.5s ease;
}

/* Оверлей */
.slider-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 30px 25px;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.85), transparent);
  transition: background 0.5s ease;
}

/* Заголовок */
.slider-title {
  font-size: 36px;
  font-weight: 800;
  margin-top: 10px;
  color: #fff;
  text-shadow: 1px 1px 5px rgba(0,0,0,0.8);
}

/* Метадані */
.slider-meta {
  font-size: 16px;
  color: #ddd;
  display: flex;
  gap: 20px;
  font-weight: 500;
}

/* Кнопки */
.slider-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.6);
  border: none;
  color: #fff;
  padding: 10px 14px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 10;
  transition: background 0.3s ease, transform 0.2s ease;
}

.slider-btn:hover {
  background: rgba(0, 0, 0, 0.85);
  transform: translateY(-50%) scale(1.1);
}

.slider-btn.prev {
  left: 20px;
}

.slider-btn.next {
  right: 20px;
}

/* Якщо новин немає */
.no-top-news {
  text-align: center;
  color: #aaa;
  font-style: italic;
  padding: 30px 0;
  font-size: 18px;
}

